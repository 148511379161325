import React, { useState } from "react";
import NavBar from "../components/Layout/NavBar/NavBar";
import Footer from "../components/Layout/Footer";

import { FaSearchLocation } from "react-icons/fa";

import "./WeatherApp.css";

const WeatherApp = () => {
  const [enteredInput, setEnteredInput] = useState();

  let api_key = "a6b42eba9781c36ee80010d5dc3f31d8";

  const keyPressHandler = (e) => {
    if (e.key === "Enter") {
      console.log("Pressed ENTER key");
    }
  };

  const onSearch = async () => {
    const element = document.getElementsByClassName("searchInput");
    if (element[0].value === "") {
      return 0;
    }
    let url = `https://api.openweathermap.org/data/2.5/weather?q=${element[0].value}&units=Metric&appid=${api_key}`;

    let response = await fetch(url);
    let data = await response.json();

    const cityName = document.getElementsByClassName("weather-location");
    const temperatur = document.getElementsByClassName("weather-temp");
    const feelsLike = document.getElementsByClassName("feelsLike-temp");
    const humidity = document.getElementsByClassName("humidity-percent");
    // const icon = document.getElementsByClassName("icon-container");

    const iconPlace = document.getElementsByClassName("icon-place");
    const icon = data.weather[0].icon;

    const imageURL = "https://openweathermap.org/img/wn/" + icon + "@2x.png";

    {
      console.log(imageURL);
      console.log(humidity);
    }

    cityName[0].innerHTML = data.name;
    temperatur[0].innerHTML = data.main.temp + " °C";
    feelsLike[0].innerHTML = data.main.feels_like + " °C";
    humidity[0].innerHTML = data.main.humidity + " %";
    iconPlace[0].setAttribute("src", imageURL);

    // Clear input value
    setEnteredInput("");
  };

  return (
    <div className="container">
      <NavBar />
      <div className="weather-container">
        <div className="weather-card">
          <div className="weather-search">
            <input
              type="text"
              className="searchInput"
              value={enteredInput}
              onChange={(e) => {
                setEnteredInput(e.target.value);
              }}
              onKeyDown={keyPressHandler}
              placeholder="Enter a city name"
            />
            <div
              className="search-icon"
              onClick={() => {
                onSearch();
              }}
            >
              <FaSearchLocation size="30px" />
            </div>
          </div>
          <div className="data-container">
            <div className="weather-location">City</div>
            <div className="weather-temp">0°C</div>
            <div className="text-feelsLike">Feels Like</div>
            <div className="feelsLike-temp">0°C</div>
            <div className="icon-container">
              <img className="icon-place" src="" />
            </div>
            <div className="humidity">Humidity</div>
            <div className="humidity-percent">30%</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WeatherApp;
