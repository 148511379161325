import React from "react";
import NavBar from "../components/Layout/NavBar/NavBar";

const FoodOrder = () => {
  return (
    <div>
      <NavBar />
      <div>FoodOrder</div>
    </div>
  );
};

export default FoodOrder;
